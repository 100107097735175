<!--
[
  { code: '', name: '전체', desc: '전체', index: 0 },
  { code: 'INITIAL', name: '검수 요청 전', desc: '검수 요청 전', index: 1 },
  { code: 'INSPECT_REQUEST', name: '검수 요청', desc: '검수 요청', index: 2 },
  { code: 'INSPECT_APPROVE', name: '검수 승인', desc: '검수 승인', index: 3 }, 
  { code: 'INSPECT_REJECT', name: '검수 반려', desc: '검수 반려', index: 4 },
  { code: 'PURCHASE_APPROVE', name: '구매 검수 승인', desc: '구매 검수 승인', index: 5 },
  { code: 'PURCHASE_REJECT', name: '구매 검수 반려', desc: '구매 검수 반려', index: 6 },
  { code: 'INSPECT_COMPLETE', name: '검수 완료', desc: '검수 완료', index: 7 },
  {
    code: 'INSPECT_REQUEST_CANCEL',
    name: '검수 요청 취소',
    desc: '검수 요청 취소',
    index: 8,
  },
  { code: 'INSPECT_DELETE', name: '검수 취소', desc: '검수 취소', index: 9 },
];
-->
<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">
          검수 확인<span class="txt_view">{{ inspectNum }}</span>
        </h2>
      </div>
      <div class="body_section">
        <!-- 발주정보 v-if="model.orderCid" -->
        <InspectOrderInfo :orderCid="model.orderCid" :orderInfo="model.orderInfo" />

        <!-- 발주 총 금액, 검수 후 잔액 -->
        <TableViewWithTitle title="발주 금액 및 검수 잔액">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col />
            <col style="width: 192px" />
            <col />
          </template>
          <template v-slot:body>
            <th>발주 총 금액</th>
            <td class="align_right">
              {{ model.totalPrice | currency }}
              <!-- {{ model.assetTypeName }} -->
            </td>
            <th>검수 후 잔액</th>
            <td class="align_right">
              {{ model.balance | currency }}
              <!-- {{ model.assetTypeName }} -->
            </td>
          </template>
        </TableViewWithTitle>

        <InspectItemInfo
          ref="InspectItemInfo"
          :dataList.sync="model.inspectItemList"
          :model="model"
          :balance="model.balance"
          @onUpdateInspectAmount="onUpdateInspectAmount"
        />
        <!-- <InspectCancelItemInfo :dataList="model.deleteOrderGoodsList" /> -->

        <!--검수 요청 전 일떄에는 입력을 못한다-->
        <!-- <div v-if="model.stateCode !== 'INITIAL'" class="area_view"> -->
        <div class="area_view">
          <TableViewWithTitle title="검수 요청 정보">
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col />
            </template>
            <template v-slot:body>
              <tr>
                <th>검수상태</th>
                <td>
                  {{ model.stateCodeName }}
                </td>
              </tr>
              <tr>
                <th>검수내역<span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <Input :maxLength="20" :value.sync="model.contentsHistory" />
                </td>
              </tr>
              <tr>
                <th>납품완료일<span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <DatePicker :date.sync="model.deliveryCompleteDate" />
                </td>
              </tr>
              <tr>
                <th>
                  검수완료일
                  <!-- <span class="ico_purchase ico_star">필수항목</span> -->
                </th>
                <td>
                  <div class="group_form">
                    <DatePicker :date.sync="model.inspectEndDate" />
                    <!-- <span v-if="isDateCompareError" class="txt_view_r tc_red">{{
                      compareDateErrorText
                    }}</span> -->
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  검수 증빙 문서<span
                    v-if="model.stateCode === 'INITIAL'"
                    class="ico_purchase ico_star"
                  >필수항목</span>
                </th>
                <td>
                  <!--<div v-if="model.stateCode == 'INITIAL' ? true : false">-->
                  <div v-if="true">
                    <FileWrite
                      :dataList="model.attachFileList ? model.attachFileList : []"
                      @fileUploaded="inspectFileUploaded"
                      @fileRemoved="inspectFileRemoved"
                    />
                  </div>
                  <div v-else>
                    <FileView :dataList="model.attachFileList ? model.attachFileList : []" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>의견</th>
                <td>
                  <Input :maxLength="40" :value.sync="model.etc" />
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </div>

        <InspectPriceHistory :dataList="model.degreeSumList ? model.degreeSumList : []" />

        <InspectHistory :dataList="model.histories" />
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
        <template v-if="model.stateCode === 'INSPECT_COMPLETE'">
          <!-- <button type="button" class="btn_fourthly btn_large" @click="onClickInspectPrint">
            검수확인서
          </button> -->
        </template>
      </div>
      <div class="area_right">
        <template v-if="isUser">
          <!-- <template v-if="model.stateCode === 'INITIAL' || model.stateCode === 'INSPECT_REQUEST'"> -->
          <!--상태 : 검수 요청 || 검수 요청전-->
          <button
            v-if="model.stateCode === 'INITIAL' || model.stateCode === 'INSPECT_REQUEST'"
            class="btn_secondary btn_large"
            @click="onClickPerformance"
          >
            수행도평가
          </button>
          <template
            v-if="model.stateCode === 'INSPECT_APPROVE' || model.stateCode === 'INSPECT_REQUEST'"
          >
            <button type="button" class="btn_secondary btn_large" @click="onClickReject">
              반려
            </button>
          </template>
          <button type="button" class="btn_primary btn_large" @click="onClickApprove">
            검수승인
          </button>
          <!-- </template> -->
        </template>
        <template
          v-if="
            $isAdmin &&
            (model.stateCode === 'INSPECT_REQUEST' || model.stateCode === 'INSPECT_APPROVE')
          "
        >
          <button type="button" class="btn_secondary btn_large" @click="onClickReject">
            구매검수반려
          </button>

          <button type="button" class="btn_primary btn_large" @click="onClickApprove">
            구매검수승인
          </button>
        </template>
      </div>
    </Sticky>
    <template v-slot:popup>
      <!--isPopInspectPrint-->
      <InspectPrintPopup
        v-if="isPopInspectPrint"
        :model="model"
        @onClickClose="onClickCloseInspectPrint"
      />
      <InspectRejectPopup
        v-if="isRejectConfirm"
        :model.sync="model"
        @onClickClose="onClickCloseAlertComfirm"
        @onClickSubmit="rejectInspect"
      />

      <!-- <AlertPopup
        v-if="isSaveConfirm"
        alertText="검수 처리 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="saveInspect"
      /> -->
      <AlertPopup
        v-if="isApproveConfirm"
        :alertText="
          model.stateCode !== 'INITIAL' ? '검수 승인 하시겠습니까?' : '검수 요청 하시겠습니까?'
        "
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="approveInspect"
      />
      <!-- !evaluateNum || -->
      <InspectEvaluatePopup
        v-if="isPopInspectEvaluatePopup"
        :evaluateNum="evaluateNum"
        :inspectId="inspectNum"
        :userNum="userNum"
        @onClickPerformanceClose="onClickPerformanceClose"
      />
      <AlertPopup
        v-if="isInspectEvaluateConfirm"
        :alertText="
          evaluateNum ? '수행도평가를 재평가하시겠습니까?' : '수행도평가를 등록하시겠습니까?'
        "
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickInspectEvaluateClose"
        @onClickPrimary="onClickInspectEvaluate"
      />
      <!-- <AlertPopup
        v-if="isPopValidationPrint"
        :alertText="'검수 금액을 확인해주세요.'"
        btnFourthlyText="확인"
        @onClickFourtyly="resetInspect"
      /> -->
      <!-- <AlertPopup
        v-if="isRejectConfirm"
        alertText="검수 반려 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="rejectInspect"
      /> -->
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableHead from "@/components/shared/TableHead";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";

import FileWrite from "@/components/common/file/FileWrite.vue";
import FileView from "@/components/common/file/FileView.vue";
import DatePicker from "@/components/common/calendar/DatePicker.vue";
import Sticky from "@/components/layout/content/Sticky";
import AlertPopup from "@/components/layout/popup/AlertPopup";

import InspectContractInfo from "@/components/admin/inspect/write/InspectContractInfo";
import InspectOrderInfo from "@/components/admin/inspect/write/InspectOrderInfo";
import InspectOrderGoodsList from "@/components/admin/inspect/write/InspectOrderGoodsList";
import InspectPriceHistory from "@/components/admin/inspect/write/InspectPriceHistory";
import InspectHistory from "@/components/admin/inspect/write/InspectHistory";
import InspectItemInfo from "@/components/admin/inspect/write/InspectItemInfo";

import InspectPrintPopup from "@/components/admin/inspect/popup/InspectPrintPopup";
import InspectRejectPopup from "@/components/admin/inspect/popup/InspectRejectPopup";
import InspectEvaluatePopup from "@/components/admin/inspect/popup/InspectEvaluatePopup";

import SupplyInfo from "@/components/admin/order/view/SupplyInfo";
import { getDateString, dateToString, stringToDate } from "@/utils/dateUtils";

import { getCountWithCurrency } from "@/utils/spUtils";
import {
  INITIAL,
  REQUESTER_INSPECT,
  REQUESTER_REJECT,
  PURCHASE_INSPECT,
  PURCHASE_CONFIRM,
  INSPECT_REQUEST,
} from "@/constants/inspectStatusList";

import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

import PageMixin from "@/mixins/PageMixin";

import InspectModel from "./InspectModel.js";

import { getMoneyToNumber, addCommaForMoney } from "@/utils/stringUtils";

export default {
  name: "InspectWrite",
  components: {
    PageWithLayout,
    TableHead,
    Sticky,
    //Popup,
    TableViewWithTitle,
    //TableView,
    FileWrite,
    FileView,
    Input,
    DatePicker,
    AlertPopup,

    InspectContractInfo,
    InspectOrderInfo,
    InspectOrderGoodsList,
    InspectHistory,
    InspectPrintPopup,
    InspectRejectPopup,
    SupplyInfo,
    InspectItemInfo,

    InspectPriceHistory,
    InspectEvaluatePopup,
  },
  mixins: [PageMixin],
  data() {
    return {
      model: new InspectModel(),
      inspectNum: null,
      userNum: null,
      // isSaveConfirm: false,
      isApproveConfirm: false,
      isRejectConfirm: false,
      isPopInspectPrint: false,
      isRefPk: false,
      isPopValidationPrint: false,
      gubun: "",
      id: "",
      inspectData: {},
      //historieData: {},
      etc: "",
      deliveryHistory: "",
      deliveryCompleteDate: null,
      inspectEndDate: null,
      inspectFileList: [],
      isDateCompareError: false,
      compareDateErrorText: "",
      totalPrice: 0,
      isOrderInspectInfo: false,

      evaluateNum: null,
      isPopInspectEvaluatePopup: false,
      isInspectEvaluateConfirm: false,
    };
  },
  computed: {
    isUser() {
      const { username } = LocalStorageManager.shared.getUserData();
      return this.model.inspect === username;
    },

    lastestHistory() {
      if (this.histories.length === 0) return null;
      // 맨 뒤가 현재 상태로 변경.
      // return this.histories[ 0 ];
      return this.histories[this.histories.length - 1];
    },
  },
  created() {
    const { refPk } = this.$route.params;
    this.inspectNum = refPk;
    this.model.isRefPk = this.$route.meta.isRefPk;
    this.model.inspectNum = refPk;

    if (this.model.isRefPk) {
      this.initData();
    }
  },
  mounted() {
    this.setInspectEndDateNow();
  },

  methods: {
    async initData() {
      const { inspectNum } = this.model;
      const path = `${this.$apiPath.ST_INSPECT}/history/${inspectNum}`;
      const result = await ApiService.shared.get(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      if (
        data.stateCode === "INSPECT_APPROVE" ||
        data.stateCode === "INSPECT_COMPLETE" ||
        data.stateCode === "INSPECT_DELETE"
      ) {
        //검수 승인, 완료, 취소일떄는 뷰페이지로 보낸다
        this.$router.push({
          path: `${this.$routerPath.INSPECT_VIEW}/${inspectNum}`,
        });
      }

      this.userNum = String(data.userNum);

      this.model.setData(data);
      this.model.setOrderInfoData(data);
      //this.model.setInspectItemInfoData(data);

      this.evaluateNum = String(data.evaluateNum) || null;
      this.isPopInspectEvaluatePopup = !data.evaluateNum && this.isUser ? true : false;
    },

    onClickApprove() {
      this.isApproveConfirm = true;
    },
    onClickReject() {
      this.isRejectConfirm = true;
    },
    onClickInspectPrint() {
      this.isPopInspectPrint = true;
    },
    onClickCloseInspectPrint() {
      this.isPopInspectPrint = false;
    },
    onClickGoToList() {
      this.goToList();
    },
    goToList() {
      this.$router.push({
        path: this.$routerPath.INSPECT_LIST,
        query: this.$route.query,
      });
    },
    onUpdateInspectAmount(totalPrice) {
      this.totalPrice = totalPrice;
      this.isPopValidationPrint = totalPrice > Number(this.model.balance) ? true : false;
    },

    //검수승인
    async approveInspect() {
      const sumInspectAmount = this.model.inspectItemList.reduce((acc, cur) => {
        let ret = acc;
        ret += Number(cur.inspectAmount);
        return ret;
      }, 0);

      if (sumInspectAmount === 0) {
        this.alert("합격 수량을 입력하세요.");
        return;
      }
      if (this.totalPrice > Number(this.model.balance) || this.totalPrice === 0) {
        this.onClickCloseAlertComfirm();
        this.alert("검수 금액을 확인해주세요.");
        return;
      }
      const calcDifference = Number(this.model.balance) - getMoneyToNumber(this.totalPrice);
      if (calcDifference > 0 && calcDifference < 1000) {
        this.onClickCloseAlertComfirm();
        this.alert(`천원이하의 차액이 발생하였습니다. <br />차액 - ${calcDifference}`);
        return;
      }

      const { inspectNum, stateCode } = this.model;
      const obj = this.model.getData();

      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.onClickCloseAlertComfirm();
        this.alert(alertMessage);
        return;
      }

      const path = `${this.$apiPath.ST_INSPECT}/approve/${inspectNum}`;
      const result = await ApiService.shared.putData(path, obj);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(result.text);
        return;
      }
      if (stateCode !== "INITIAL") {
        this.resetInfo(obj);
      }

      //검수 완료 일떄만 완료 API를 태운다
      if (data.stateCode === "PURCHASE_APPROVE") {
        // const path = `${this.$apiPath.ST_INSPECT}/complete/${inspectNum}`;
        // const result = await ApiService.shared.putData(path, data);
        // const { code, text } = result;
        // if (code !== '200') {
        //   this.alert(result.text);
        //   return;
        // }
        // this.goToList();
      } else {
        this.goToList();
      }
    },

    //검수반려
    async rejectInspect() {
      const { inspectNum } = this.model;
      const alertMessage = this.model.getRejectAlertMessage();
      if (alertMessage) {
        this.onClickCloseAlertComfirm();
        this.alert(alertMessage);
        return;
      }
      let obj = this.model.getData();

      this.resetInfo(obj);

      const path = `${this.$apiPath.ST_INSPECT}/reject/${inspectNum}`;
      const result = await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(result.text);
        return;
      }
      this.rejectComplete(obj);
    },
    async rejectComplete(obj) {
      const { inspectNum } = this.model;
      const path = `${this.$apiPath.ST_INSPECT}/complete/${inspectNum}`;
      const result = await ApiService.shared.putData(path, obj);
      const { code, text } = result;
      if (code !== "200") {
        this.alert(result.text);
        return;
      }

      this.goToList();
    },

    resetInfo(obj) {
      obj.attachFileList = [];
      obj.contentsHistory = "";
      obj.deliveryCompleteDate = null;
      obj.etc = "";
    },

    onClickCloseAlertComfirm() {
      this.isSaveConfirm = false;
      this.isApproveConfirm = false;
      this.isRejectConfirm = false;
      this.isPopValidationPrint = false;
    },

    inspectFileUploaded(files) {
      this.model.attachFileList = this.model.attachFileList.concat(files);
    },
    inspectFileRemoved(itemToRemove) {
      this.model.attachFileList = this.model.attachFileList.filter((item) => item !== itemToRemove);
    },

    setInspectEndDateNow() {
      this.model.inspectEndDate = new Date(this.moment(new Date()).set({ hour: 0, minute: 0 }));
    },
    onClickPerformance() {
      this.isPopInspectEvaluatePopup = true;
    },
    onClickPerformanceClose() {
      this.isPopInspectEvaluatePopup = false;
    },
    onClickInspectEvaluateConfirm() {
      this.isInspectEvaluateConfirm = true;
    },
    onClickInspectEvaluateConfirmClose() {
      this.isInspectEvaluateConfirm = false;
    },
  },
};
</script>
