<template>
  <div class="area_view">
    <TableHead title="납품 내역 및 최종 납품가" :isEssential="isEssential" />
    <Board class="tbl_list_12" :disableHover="true" :dataList="dataList" :showPagination="false">
      <template v-slot:colgroup>
        <!-- NO. -->
        <col style="width: 62px" />
        <!-- 품명 -->
        <col style="width: 226px" />
        <!-- 발주 수량 -->
        <col style="width: 120px" />
        <!-- 단가 -->
        <col style="width: 100px" />
        <!-- 발주 금액 -->
        <col style="width: 100px" />
        <!-- 기납품 수량 -->
        <col style="width: 120px" />
        <!-- 납품 수량 -->
        <col style="width: 120px" />
        <!-- 합격 수량 -->
        <col style="width: 120px" />
        <!-- 기검수 금액 -->
        <col style="width: 152px" />
        <!-- 검수 금액 -->
        <col style="width: 152px" />
        <!-- 비고 -->
        <col />
      </template>
      <template v-slot:tr>
        <th>No.</th>
        <th>품명</th>
        <th>발주 수량</th>
        <th>단가</th>
        <th>발주 금액</th>
        <th>기납품 수량</th>
        <th>납품 수량</th>
        <th>합격 수량</th>
        <th>기검수 금액</th>
        <th>검수 금액</th>
        <th>품목상세</th>
      </template>
      <template v-slot:rows>
        <InspectInfoLine
          v-for="(item, index) in dataList"
          :key="index"
          :rowData="item"
          :index="index"
        />
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="11" class="td_empty">납품내역이 없습니다.</td>
        </tr>
      </template>
    </Board>
    <!-- <InspectFinalPrice
      :model="inspectFinalPriceModel"
      :currency="currency"
      :cutOffPrice.sync="cutOffPriceSync"
    /> -->
    <template>
      <div class="tbl_foot">
        <div class="area_total">
          <dl class="list_total">
            <dt class="">검수 금액</dt>
            <dd class="">
              <span class="txt_price">{{ totalPrice | currency }}</span>
            </dd>
          </dl>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
import Board from "@/components/common/board/Board";
import InspectInfoLine from "./InspectInfoLine";

// import Input from '@/components/common/input/Input'
// import InputAutoMoney from '@/components/common/input/InputAutoMoney'
// import SelectBoxQuantityIncludeData from '@/components/common/selectBox/SelectBoxQuantityIncludeData'
import SelectBoxCurrencyNoInputCount from "@/components/common/selectBox/SelectBoxCurrencyNoInputCount";
import SelectBox from "@/components/common/selectBox/SelectBox";
import InspectFinalPrice from "./InspectFinalPrice";

import { getMoneyToNumber, addCommaForMoney } from "@/utils/stringUtils";

//import InspectItemModel from './InspectItemModel';
import InspectFinalPriceModel from "./InspectFinalPriceModel";

export default {
  name: "InspectItemInfo",
  components: {
    TableHead,
    Board,
    InspectInfoLine,

    // Input,
    // InputAutoMoney,
    // SelectBoxQuantityIncludeData,
    SelectBoxCurrencyNoInputCount,
    SelectBox,
    InspectFinalPrice,
  },
  props: {
    model: Object,
    dataList: Array,
    note: String,
    currency: String,
    // quantity: String,
    isAnnounceConnected: Boolean, // 공고 연결 시에는 disabled
    cutOffPrice: String,
    vat: String,
    balance: Number,
  },
  data() {
    return {
      // cutOffPrice: '',
      isEssential: true,
      totalCount: 0,
      inspectFinalPriceModel: new InspectFinalPriceModel(),
      vatDataList: [
        // {code:'vatChoice',desc:'부가세(VAT) 선택',name:'부가세(VAT) 선택',value:'vatChoice'},
        { code: "별도", desc: "부가세(VAT) 별도", name: "부가세(VAT) 별도", value: "별도" },
        { code: "포함", desc: "부가세(VAT) 포함", name: "부가세(VAT) 포함", value: "포함" },
        { code: "미포함", desc: "적용안함", name: "적용안함", value: "미포함" },
      ],
    };
  },
  computed: {
    resultAmount() {
      const initialValue = 0;
      const result = this.dataList.reduce((accumulator, item) => {
        const nAmount = getMoneyToNumber(item.quantity);

        return accumulator + nAmount;
      }, initialValue);

      return String(result);
    },
    resultPrice() {
      const initialValue = 0;
      const result = this.dataList.reduce((accumulator, item) => {
        const nPrice = getMoneyToNumber(item.totalPrice);
        return accumulator + nPrice;
      }, initialValue);

      return String(result);
    },
    resultSumPrice() {
      const resultPrice = Number.parseInt(this.resultPrice, 10);
      const nCutOffPrice = getMoneyToNumber(this.cutOffPrice);
      const result = resultPrice - nCutOffPrice;
      return String(result);
    },
    totalPrice() {
      let result = 0;
      this.dataList.forEach((item) => {
        let inspectPrice = item.inspectPrice ? getMoneyToNumber(item.inspectPrice) : 0;
        result = result + inspectPrice;
      });
      if (this.model.stateCode === "INSPECT_REQUEST") {
        if (result === 0) {
          result = this.model.requestTotalPrice;
        }
      }

      this.$emit("onUpdateInspectAmount", result);
      return result;
    },
  },

  watch: {},
  methods: {},
};
</script>
