<template>
  <tr>
    <!-- No -->
    <td>{{ index + 1 }}</td>
    <!-- 품명 -->
    <td class="align_left">
      {{ rowData.itemName }}
    </td>
    <!-- 발주수량 -->
    <td class="align_right">
      {{ rowData.quantity | currency }}
    </td>
    <!-- 단가 -->
    <td class="align_right">{{ rowData.inspectUnitPrice | currency }}</td>
    <!-- 발주금액 -->
    <td class="align_right">{{ rowData.price | currency }}</td>
    <!-- 기납품수량 -->
    <td class="align_right">
      {{ Number(rowData.inspectedAmount) > 0 ? rowData.inspectedAmount : "-" | currency }}
    </td>
    <!-- 납품수량 -->
    <td>
      <InputNumber
        :inputClass="'align_right'"
        :isDisabled="computedDisabledAmounts"
        :maxValue="Number(computedMaxDeliveryQuantity)"
        :value.sync="rowData.deliveryQuantity"
      />
    </td>
    <!-- 합격 수량 -->
    <td>
      <InputNumber
        :inputClass="'align_right'"
        :isDisabled="computedDisabledAmounts"
        :maxValue="Number(rowData.deliveryQuantity)"
        :value.sync="rowData.inspectAmount"
      />
      <!--
         @update:value="(value) => $emit('onUpdateInspectAmount', value, rowData)"
      -->
    </td>
    <!-- 기검수금액 -->
    <td class="align_right">
      {{ Number(rowData.inspectedPrice) > 0 ? rowData.inspectedPrice : "-" | currency }}
    </td>
    <!-- 검수금액 -->
    <td>
      <InputAutoMoney
        ref="inspectPrice"
        :isDisabled="computedDisabledInspectPrice"
        :inputClass="'align_right'"
        :value.sync="rowData.inspectPrice"
      />
    </td>
    <!-- 품목상세 -->
    <td class="align_left td_ellip">
      {{ rowData.note }}
    </td>
  </tr>
</template>

<script>
import Input from "@/components/common/input/Input";
import InputNumber from "@/components/common/input/InputNumber";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import SelectBoxQuantityIncludeData from "@/components/common/selectBox/SelectBoxQuantityIncludeData";
import SelectBox from "@/components/common/selectBox/SelectBox";
import { getMoneyToNumber } from "@/utils/stringUtils";

export default {
  name: "InspectInfoLine",
  components: {
    Input,
    InputNumber,
    InputAutoMoney,
    SelectBoxQuantityIncludeData,
    SelectBox,
  },
  props: {
    rowData: Object,
    index: Number,
  },
  data() {
    return {};
  },

  computed: {
    computedMaxDeliveryQuantity() {
      const { quantity, inspectedAmount } = this.rowData;
      let ret = 0;
      if (inspectedAmount) {
        ret = Number(quantity) - Number(inspectedAmount);
      } else {
        ret = Number(quantity);
      }
      return ret.toFixed(2);
    },
    computedDisabledInspectPrice() {
      let ret = false;
      const price = getMoneyToNumber(this.rowData.price);
      const inspectedPrice = getMoneyToNumber(this.rowData.inspectedPrice);
      ret = price === inspectedPrice;
      return ret;
    },
    computedDisabledAmounts() {
      let ret = false;
      const quantity = getMoneyToNumber(this.rowData.quantity);
      const inspectedAmount = getMoneyToNumber(this.rowData.inspectedAmount);
      ret = quantity === inspectedAmount;
      return ret;
    },
  },
  // created() {
  //   this.rowData.inspectPrice = this.rowData.unitPrice * this.rowData.inspectAmount;
  // },
  watch: {
    "rowData.inspectAmount": function (newVal, oldVal) {
      const { inspectUnitPrice, deliveryQuantity, inspectPrice } = this.rowData;
      if (Number(deliveryQuantity) < Number(newVal)) {
        this.$toast("합격수량은 납품 수량을 넘을수 없습니다.");
      }

      const calc = _.floor(inspectUnitPrice) * Number(newVal);
      this.rowData.inspectPrice = String(Math.round((_.floor(calc) * 10) / 10));
    },
    "rowData.inspectPrice": function (newVal, oldVal) {
      const inspectPrice = getMoneyToNumber(newVal);
      /** 발주금액 */
      const price = getMoneyToNumber(this.rowData.price);
      /** 기겁수금액 */
      const inspectedPrice = getMoneyToNumber(this.rowData.inspectedPrice);
      if (price < inspectedPrice + inspectPrice) {
        this.$toast("검수금액은 발주금액을 넘을수 없습니다.");
        this.rowData.inspectPrice = price - inspectedPrice;
        this.$refs.inspectPrice.blur();
      }
    },
    // price() {
    //   this.$emit('changePrice', this.rowData);
    // },
    // 'rowData.inspectAmount': function (newVal, oldVal) {
    //   this.$emit('onUpdateInspectAmount', newVal, this.rowData);
    // },
  },
  methods: {},
};
</script>
