import LocalStorageManager from "@/LocalStorageManager";
export default class InspectEvaluateModel {
  constructor() {
    this.userData = LocalStorageManager.shared.getUserData();

    this.templateType = "";
    this.companyName = "";
    this.ceo = "";
    this.regDate = "";
    this.regName = "";
    this.regId = "";
    this.partnerCutOffPoint = "";

    this.inspectNum = "";
    this.userNum = "";

    this.questList = [];
    this.templateData = {
      code: "",
      name: "",
      templateDesc: "",
      templateId: "",
      templateName: "",
      templateType: "",
      templateTypeName: "",
    };
    // this.keywordDataList = [];

    this.evaluateData = {};
  }

  setPartnerData(obj) {
    this.companyName = obj.companyName;
    this.ceo = obj.ceo;
    this.userNum = obj.userNum;

    this.regId = this.userData.username;
    // const now = moment().format('YYYYMMDDHHmmss')
    this.regDate = "";
    this.partnerCutOffPoint = obj.evaluateInfo.partnerCutOffPoint;
  }

  setPartnerModifyData(obj, evaluateData) {
    this.reason = "";
    this.companyName = obj.companyName;
    this.ceo = obj.ceo;
    this.userNum = evaluateData.userNum;
    this.regId = evaluateData.regId;
    this.regName = evaluateData.regName || "";
    this.regDate = evaluateData.regDate;
    this.partnerCutOffPoint = evaluateData.partnerCutOffPoint;
    this.evaluateData = evaluateData;
  }

  setQuestList(obj) {
    this.questList = [];
    obj.questList.forEach((item) => {
      const tmp = {
        ...item,
        optionType:
          item.optionList && item.optionList.length > 0 ? item.optionList[0].optionType : "",
        selection: "",
        points: 0,
        optionNum: "",
        value: "",
      };
      if (this.evaluateData.answerList && this.evaluateData.answerList.length > 0) {
        // 수정
        const idx = this.evaluateData.answerList.findIndex((answer) => {
          return answer.questNum === item.questNum;
        });
        if (idx > -1) {
          tmp.value =
            tmp.optionType === "M"
              ? this.evaluateData.answerList[idx].selection
              : this.evaluateData.answerList[idx].optionNum;
          tmp.optionNum = this.evaluateData.answerList[idx].optionNum;
          tmp.points = this.evaluateData.answerList[idx].points;
          tmp.selection = this.evaluateData.answerList[idx].selection;
        }
        this.questList.push(tmp);
      } else {
        // 신규
        this.questList.push(tmp);
      }
    });
  }

  getData() {
    const tmp = {
      // templateType: this.templateType,
      userNum: this.userNum,
      evaluateName: this.templateData.templateName,
      templateId: this.templateData.templateId,
      inspectNum: this.inspectNum,
      answerList: [],
    };
    if (this.evaluateData.useYn) {
      tmp.reason = this.reason;
    }
    this.questList.forEach((item) => {
      tmp.answerList.push({
        questNum: item.questNum,
        question: item.question,
        optionNum: item.optionNum,
        optionType: item.optionType,
        selection: item.selection,
        points: item.points,
      });
    });
    return tmp;
  }

  getAlertMessage() {
    // vaildation
    if (this.evaluateData.useYn) {
      if (!this.reason) {
        return "재평가 사유를 입력해주세요.";
      }
    }

    let tmp = "";

    for (let i = 0; i < this.questList.length; i++) {
      if (!this.questList[i].selection) {
        tmp = "값을 입력해주세요";
        break;
      }
    }
    if (tmp) {
      return tmp;
    }

    return null;
  }
}
