<template>
  <div>
    <Popup popupType="tableType" width="940px">
      <div class="section_comm">
        <div class="head_section">
          <h3 class="tit_section">수행도평가</h3>
        </div>
        <div class="body_section">
          <TableViewWithTitle title="거래정보">
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col style="width: 267px" />
              <col style="width: 192px" />
              <col style="width: 267px" />
            </template>
            <template v-slot:body>
              <template v-if="!evaluateNum">
                <tr>
                  <th>업체명</th>
                  <td colspan="3">{{ itemData.companyName }}</td>
                </tr>
                <tr>
                  <th>평가자</th>
                  <td colspan="3">{{ model.userData.name }}({{ model.userData.username }})</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <th>평가일</th>
                  <td>{{ model.regDate | dateStringFormat }}</td>
                  <th>평가자</th>
                  <td>{{ `${model.regName} (${model.regId})` }}</td>
                </tr>
              </template>
            </template>
          </TableViewWithTitle>

          <TableViewWithTitle title="평가 템플릿">
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col style="width: 724px" />
            </template>
            <template v-slot:body>
              <tr>
                <td>평가템플릿 선택</td>
                <td>
                  <SelectBox
                    class="w152"
                    :dataList="templateOption"
                    :value.sync="templateId"
                    @onChange="onChangeTemplateType"
                  />
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
          <template v-if="model.questList && model.questList.length">
            <TableViewWithTitle title="평가항목">
              <template v-slot:colgroup>
                <col style="width: 192px" />
                <col style="width: 724px" />
              </template>
              <template v-slot:body>
                <tr v-for="(quest, qIdx) in model.questList" :key="`quest_${qIdx}`">
                  <th>{{ qIdx + 1 }}</th>
                  <td>
                    <p class="desc_view">{{ quest.question }}</p>
                    <template v-if="quest.optionList.length > 0">
                      <template v-if="quest.optionType === 'S'">
                        <PerformanceRadio
                          :name="`${qIdx}_${quest.optionList[0].optionNum}`"
                          :dataList="quest.optionList"
                          :selectedId.sync="quest.value"
                          :qIdx="qIdx"
                          @onChange="onChangeRadio"
                        />
                      </template>
                      <template v-else-if="quest.optionType === 'M'">
                        <Input
                          :value.sync="quest.value"
                          :maxLength="50"
                          placeholder="직접입력해주세요"
                          @update:value="onChangeText(qIdx, quest)"
                        />
                      </template>
                    </template>
                    <template v-else>
                      <p class="desc_view">등록된 항목이 없습니다.</p>
                    </template>
                  </td>
                </tr>
              </template>
            </TableViewWithTitle>
            <TableViewWithTitle v-if="evaluateNum" title="">
              <template v-slot:colgroup>
                <col style="width: 192px" />
                <col style="width: 724px" />
              </template>
              <template v-slot:body>
                <tr>
                  <th>재평가 사유</th>
                  <td>
                    <Textarea :value.sync="model.reason" placeholder="재평가 사유를 입력하세요." />
                  </td>
                </tr>
              </template>
            </TableViewWithTitle>
          </template>
          <TableViewWithTitle
            v-if="evaluateData.exEvaluateList && evaluateData.exEvaluateList.length > 0"
            title="평가내역"
          >
            <template v-slot:colgroup>
              <col style="width: 100px" />
              <col style="width: 120px" />
              <col style="width: 80px" />
              <col style="width: 616px" />
            </template>
            <template v-slot:header>
              <tr class="tr_eval">
                <th>평가일자</th>
                <th>평가자</th>
                <th>총점</th>
                <th>사유</th>
              </tr>
            </template>
            <template v-slot:body>
              <tr v-for="(item, idx) in evaluateData.exEvaluateList" :key="idx">
                <td class="align_center">{{ item.regDate | dateStringFormat }}</td>
                <td class="align_center">{{ item.regId }}</td>
                <td class="align_center">{{ item.totalScore }}</td>
                <td>{{ item.reason }}</td>
              </tr>
            </template>
          </TableViewWithTitle>
        </div>
      </div>
      <div class="group_btn">
        <button class="btn_fourthly btn_large" @click="onClickCancel">취소</button>

        <button v-if="evaluateNum" class="btn_primary btn_large" @click="onClickAlertConfirm">
          재평가진행
        </button>
        <button v-else class="btn_primary btn_large" @click="onClickAlertConfirm">등록</button>
      </div>
    </Popup>
    <AlertPopup
      v-if="isAlertConfirm"
      :alertText="alertText"
      btnFourthlyText="취소"
      btnPrimaryText="확인"
      @onClickFourtyly="onClickCloseAlertConfirm"
      @onClickPrimary="onClickConfirm"
    />

    <AlertPopup
      v-if="isAlertCancel"
      alertText="수행도평가를 취소하시겠습니까?"
      btnFourthlyText="취소"
      btnPrimaryText="확인"
      @onClickFourtyly="onClickAlertCancel"
      @onClickPrimary="onClickAlertOk"
    />
  </div>
</template>

<script>
import { Fragment } from "vue-fragment";
import Popup from "@/components/layout/popup/Popup";
import SelectBox from "@/components/common/selectBox/SelectBox";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Radio from "@/components/common/radio/Radio";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import Textarea from "@/components/common/textarea/Textarea";
import TableHead from "@/components/shared/TableHead";
import ApiService from "@/services/ApiService";
import PerformanceRadio from "@/components/admin/performance/write/PerformanceRadio";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import Input from "@/components/common/input/Input";
import PageMixin from "@/mixins/PageMixin";

import InspectEvaluateModel from "./InspectEvaluateModel";

export default {
  name: "PartnerEvaluatePopup",
  components: {
    Fragment,
    Input,
    PerformanceRadio,
    SelectBox,
    Popup,
    TableViewWithTitle,
    Radio,
    InputAutoMoney,
    Textarea,
    SelectBox,
    TableHead,
    AlertPopup,
  },
  mixins: [PageMixin],
  props: {
    inspectId: String,
    userNum: String,
    evaluateNum: String,
  },
  data() {
    return {
      model: new InspectEvaluateModel(),
      isAlertCancel: false,
      isAlertConfirm: false,

      itemData: {},
      evaluateData: {},
      templateOption: [],

      templateId: null,
      selectedScore: [],
      score: 0,
    };
  },
  computed: {
    alertText() {
      return this.evaluateNum
        ? "수행도평가를 재평가하시겠습니까?"
        : "수행도평가를 등록하시겠습니까?";
    },
    computedUserNum() {
      return this.userNum;
    },
  },
  watch: {
    "model.questList": {
      handler: function (newVal, oldVal) {
        this.setScore();
      },
      deep: true,
    },
  },
  async created() {
    if (this.evaluateNum) {
      const evaluateData = await this.getEvaluateData(this.evaluateNum);
      this.evaluateData = evaluateData;
      this.model.setPartnerModifyData({}, evaluateData);
      if (evaluateData.inspectNum) {
        this.inspectId = evaluateData.inspectNum;
        this.model.inspectNum = evaluateData.inspectNum;
      }
      if (evaluateData.templateId) {
        this.templateId = evaluateData.templateId;
        await this.getPerformanceTemplateData(this.templateId);
      }
    } else {
      await this.getEvaluateTemplate("PE");
      if (this.templateOption && this.templateOption.length > 0) {
        this.model.inspectNum = this.inspectId;
        this.model.userNum = this.computedUserNum;

        if (!this.computedUserNum) {
          return;
        }
        await this.getData();

        this.templateId = this.templateOption[0].templateId;
        this.onChangeTemplateType(this.templateId);
      }
    }
  },

  methods: {
    async getData() {
      const path = `${this.$apiPath.PARTNER}/${this.computedUserNum}`;
      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        this.alert(result.text);
        return;
      }

      this.itemData = result.data;
    },
    async getEvaluateTemplate(type) {
      const path = `${this.$apiPath.ST_EVALUATE_TEMPLATE}/${type}`;

      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        this.templateOption = [];
      }

      this.templateOption = result.data.map((item) => {
        return {
          code: item.templateId,
          name: item.templateName,
          ...item,
        };
      });
    },
    async getEvaluateData(evaluateNum) {
      const path = `${this.$apiPath.ST_EVALUATE_EVALUATE}/${evaluateNum}`;
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        return {};
      }

      return result.data;
    },
    onClickConfirm() {
      this.submit();
    },
    submit() {
      const obj = this.model.getData();

      if (!this.evaluateNum) {
        this.setPartnerEvaluate(obj);
      } else {
        const id = this.evaluateNum;
        this.setPartnerEvaluateModify(id, obj);
      }
    },
    async setPartnerEvaluate(data) {
      const path = `${this.$apiPath.ST_EVALUATE_EVALUATE}`;

      const result = await ApiService.shared.postData(path, data);
      if (!result.data) return;

      this.onClickAlertOk();
    },
    // 재평가
    async setPartnerEvaluateModify(evaluateNum, data) {
      const path = `${this.$apiPath.ST_EVALUATE_EVALUATE}/${evaluateNum}`;

      data.useYn = false;
      const result = await ApiService.shared.postData(path, data);
      if (!result.data) return;

      this.onClickAlertOk();
    },
    onClickCancel() {
      this.$emit("onClickPerformanceClose");
    },
    onClickAlertOk() {
      this.onClickCloseAlertConfirm();
      window.location.reload();
      // if (this.evaluateNum) {
      //   this.$router.push({
      //     path: `${this.$routerPath.PERFORMANCE_VIEW}/${this.evaluateNum}`,
      //   });
      // } else {
      //   this.$router.push({
      //     path: `${this.$routerPath.INSPECT_VIEW}/${this.inspectId}`,
      //   });
      // }
    },
    onClickAlertCancel() {
      this.isAlertCancel = false;
    },
    onClickAlertConfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isAlertConfirm = true;
    },
    onClickCloseAlertConfirm() {
      this.isAlertConfirm = false;
    },
    onChangeTemplateType(val) {
      this.getPerformanceTemplateData(val);
    },
    async getPerformanceTemplateData(id) {
      const path = `${this.$apiPath.ST_EVALUATE}/${id}`;
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        this.model.templateData = {};
        return;
      }
      const { data } = result;
      this.model.setQuestList(data);
      this.model.templateData = data;

      if (data.questList && data.questList.length > 0) {
        this.selectedScore = new Array(data.questList.length);
      }
    },
    onChangeRadio(idx, item) {
      this.selectedScore[idx] = item.score;

      const target = this.model.questList[idx];

      target.optionNum = item.optionNum;
      target.selection = item.selection;
      target.points = item.score;
    },
    onChangeText(idx, item) {
      this.selectedScore[idx] = item.value.trim().length > 0 ? item.optionList[0].score || 0 : 0;
      item.optionNum = item.optionList[0].optionNum;
      item.points = item.value.trim().length > 0 ? item.optionList[0].score || 0 : 0;
      item.selection = item.value;
    },
    setScore() {
      this.score = this.selectedScore.reduce((acc, item, index) => {
        let ret = acc;
        ret += item;
        return ret;
      }, 0);
    },
  },
};
</script>
<style scoped>
.tr_eval th {
  height: auto !important;
}
</style>
